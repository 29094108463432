import { ref } from 'vue';
import axios from '@/services/useAxios';

export default function useDiagnotisco() {
    const diagnostico = ref(null);
    const diagnosticos = ref([]);

    const getDiagnostico = async (id) => {
        const res = await axios.get(`/care/diagnosticos/${id}`);

        if (res.status === 200) {
            diagnostico.value = res.data;
        }
    };

    const getDiagnosticos = async (searchQuery) => {
        const query = searchQuery ? `?searchQuery=${searchQuery}` : '';

        const res = await axios.get(`/care/diagnosticos${query}`);

        if (res.status === 200) {
            diagnosticos.value = res.data.items;
        }
    };

    const createDiagnostico = async (data) => {
        const res = await axios.post('/care/diagnosticos', data);
        if (res.status === 201) {
            diagnostico.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const updateDiagnostico = async (id, data) => {
        const res = await axios.put(`/care/diagnosticos/${id}`, data);
        if (res.status === 200) {
            // console.log(res.data);
            diagnostico.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const removeDiagnostico = async (id) => {
        const res = await axios.delete(`/care/diagnosticos/${id}`);
        if (res.status === 200) {
            // console.log(res.data);
            diagnostico.value = undefined;
            if (diagnosticos.value) {
                diagnosticos.value = diagnosticos.value.filter((e) => e.id !== id);
            }
        } else {
            throw new Error(res.data.errors);
        }
    };

    return {
        diagnostico,
        diagnosticos,
        getDiagnostico,
        getDiagnosticos,
        createDiagnostico,
        updateDiagnostico,
        removeDiagnostico,
    };
}
