<template>
    <div class="row">
        <div :class="{ 'col-lg-6': !disabled, 'col-lg-12': disabled }">
            <div class="h-100 w-100 border">
                <p v-for="(d, i) of modelValue" :key="i" class="mb-0">
                    {{ i + 1 }}. {{ d }}
                    <i
                        v-if="!disabled"
                        role="button"
                        @click="handleMoveAyuda(i, '-')"
                        class="fas fa-chevron-up p-1"
                    ></i>
                    <i
                        v-if="!disabled"
                        role="button"
                        @click="handleMoveAyuda(i, '+')"
                        class="fas fa-chevron-down p-1"
                    ></i
                    ><i
                        v-if="!disabled"
                        role="button"
                        @click="handleDeleteAyuda(i)"
                        class="fa fa-trash text-danger p-1"
                    ></i>
                </p>
            </div>
        </div>
        <div class="col-lg-6" v-if="!disabled">
            <div class="row">
                <label class="col-md-3 col-form-label fw-light">Buscar ayuda diagnostica</label>
                <div class="col-md-9">
                    <Autocomplete
                        :debounce="1200"
                        @onInput="getAyudas"
                        :items="ayudas"
                        :displayItem="(item) => (typeof item === 'string' ? item : item.selectName)"
                        placeholder="Buscar ayuda diagnostica"
                        @onSelect="onSelectAyuda"
                        ref="ayudaAutocomplete"
                        :key="autocompleteKey"
                    ></Autocomplete>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue';
import Autocomplete from '@/components/form/Autocomplete.vue';
import useAyudas from '@/modules/Care/AyudasDiagnosticas/services/useAyudaDiagnosticas';

export default {
    name: 'AyudasRipsForm',
    components: { Autocomplete },
    props: {
        modelValue: Array,
        disabled: Boolean,
    },
    setup(props, { emit }) {
        const { ayudas, getAyudas } = useAyudas();

        const autocompleteKey = ref(Math.floor(Math.random() * 1000));
        const ayudaAutocomplete = ref(); // autocomplete input
        const onSelectAyuda = (selected) => {
            if (Array.isArray(props.modelValue)) {
                emit('update:modelValue', [...props.modelValue, selected.selectName]);
            } else {
                emit('update:modelValue', [selected.selectName]);
            }
            ayudaAutocomplete.value.setText(selected.selectName);
            ayudas.value = [];
            autocompleteKey.value = Math.floor(Math.random() * 1000);
        };

        const handleMoveAyuda = (index, op) => {
            const temp = [...props.modelValue];
            const value = props.modelValue[index];
            temp.splice(index, 1);
            if (op === '+') {
                temp.splice(index + 1, 0, value);
            } else {
                temp.splice(index - 1, 0, value);
            }
            emit('update:modelValue', temp);
        };

        const handleDeleteAyuda = (index) => {
            const temp = [...props.modelValue];
            temp.splice(index, 1);
            emit('update:modelValue', temp);
        };

        return {
            ayudas,
            getAyudas,
            onSelectAyuda,
            ayudaAutocomplete,
            handleMoveAyuda,
            handleDeleteAyuda,
            autocompleteKey,
        };
    },
};
</script>
<style scoped></style>
