<template>
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col" class="table-primary fw-light">Ojo Derecho</th>
                    <th scope="col" class="table-primary fw-light">Ojo Izquierdo</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="fw-light">Agudeza Visual</td>
                    <td>
                        <textarea
                            rows="3"
                            class="form-control"
                            v-model="state.derecho_agudeza"
                            :disabled="disabled"
                        ></textarea>
                    </td>
                    <td>
                        <textarea
                            rows="3"
                            class="form-control"
                            v-model="state.izquierdo_agudeza"
                            :disabled="disabled"
                        ></textarea>
                    </td>
                </tr>
                <tr>
                    <td class="fw-light">Refracción</td>
                    <td>
                        <textarea
                            rows="3"
                            class="form-control"
                            v-model="state.derecho_refraccion"
                            :disabled="disabled"
                        ></textarea>
                    </td>
                    <td>
                        <textarea
                            rows="3"
                            class="form-control"
                            v-model="state.izquierdo_refraccion"
                            :disabled="disabled"
                        ></textarea>
                    </td>
                </tr>
                <tr>
                    <td class="fw-light">Subjetivo</td>
                    <td>
                        <textarea
                            rows="3"
                            class="form-control"
                            v-model="state.derecho_adiccion"
                            :disabled="disabled"
                        ></textarea>
                    </td>
                    <td>
                        <textarea
                            rows="3"
                            class="form-control"
                            v-model="state.izquierdo_adiccion"
                            :disabled="disabled"
                        ></textarea>
                    </td>
                </tr>
                <tr>
                    <td class="fw-light">PIO</td>
                    <td>
                        <textarea
                            rows="3"
                            class="form-control"
                            v-model="state.derecho_pio"
                            :disabled="disabled"
                        ></textarea>
                    </td>
                    <td>
                        <textarea
                            rows="3"
                            class="form-control"
                            v-model="state.izquierdo_pio"
                            :disabled="disabled"
                        ></textarea>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { onBeforeMount, reactive, watch } from 'vue';

export default {
    name: 'OjosForm',
    props: {
        modelValue: Object,
        disabled: Boolean,
    },
    setup(props, { emit }) {
        const state = reactive({
            derecho_agudeza: '',
            derecho_refraccion: '',
            derecho_adiccion: '',
            derecho_pio: '',
            izquierdo_agudeza: '',
            izquierdo_refraccion: '',
            izquierdo_adiccion: '',
            izquierdo_pio: '',
        });

        const setState = (data) => {
            if (data) {
                state.derecho_agudeza = data.derecho_agudeza;
                state.derecho_refraccion = data.derecho_refraccion;
                state.derecho_adiccion = data.derecho_adiccion;
                state.derecho_pio = data.derecho_pio;
                state.izquierdo_agudeza = data.izquierdo_agudeza;
                state.izquierdo_refraccion = data.izquierdo_refraccion;
                state.izquierdo_adiccion = data.izquierdo_adiccion;
                state.izquierdo_pio = data.izquierdo_pio;
            }
        };

        onBeforeMount(() => {
            if (props.modelValue) {
                setState(props.modelValue);
            }
        });

        watch(
            () => props.modelValue,
            (newVal) => {
                setState(newVal);
                // eslint-disable-next-line comma-dangle
            }
        );

        watch(state, (newVal) => {
            emit('update:modelValue', newVal);
            // eslint-disable-next-line comma-dangle
        });

        return { state, setState };
    },
};
</script>
<style scoped></style>
