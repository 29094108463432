<template>
    <div class="file d-flex flex-column">
        <div class="w-100 p-2 g-0 text-center">
            <div style="background-color: #ccc" class="p-2" @click="openModal" role="button">
                <vue-pdf-embed
                    class="vue-pdf-embed"
                    :disableTextLayer="false"
                    :disableAnnotationLayer="false"
                    :source="url"
                    :page="1"
                />
            </div>
        </div>
        <div class="row g-0 p-2 mt-auto">
            <div class="col-md-8 mb-2">{{ file.title }}</div>

            <div class="d-flex justify-content-end col-md-4 pe-2 mb-2">
                <div class="mt-auto">
                    <i role="button" class="mx-1 far fa-eye" @click="openModal"></i
                    ><i
                        role="button"
                        v-if="disabled"
                        class="mx-1 far fa-download"
                        @click="handleDownloadDocument()"
                    ></i
                    ><i
                        role="button"
                        v-if="!disabled"
                        class="mx-1 far fa-trash-alt"
                        @click="$emit('onRemoveRender', file.id)"
                    ></i>
                </div>
            </div>
        </div>
        <DocumentViewerModal
            v-if="file.mimeType === 'application/pdf'"
            :modal-id="modalId"
            :title="file.title"
            :url="url"
            @onClose="closenModal"
        />
        <ImageViewerModal
            v-if="file.mimeType === 'image/jpeg' || file.mimeType === 'image/png'"
            :modal-id="modalId"
            :title="file.title"
            :url="url"
            @onClose="closenModal"
        />
    </div>
</template>
<script>
import { useVfm } from 'vue-final-modal';
import VuePdfEmbed from 'vue-pdf-embed';
import useDownload from '@/services/useDownload';
import DocumentViewerModal from '../../Resultados/components/DocumentViewerModal.vue';

export default {
    name: 'ConsultaRenderedFile',
    components: { DocumentViewerModal, VuePdfEmbed },
    props: {
        file: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },
    setup(props) {
        const url = `${process.env.VUE_APP_STATIC}/v1/care/consultas/viewer/document/${props.file.id}`;

        const { downloadPdf } = useDownload();
        const vfm = useVfm();

        // eslint-disable-next-line vue/no-setup-props-destructure
        const modalId = props.file.id;

        const handleDownloadDocument = () => {
            downloadPdf(url, props.file.title);
        };

        const openModal = () => {
            vfm.open(modalId);
        };

        const closenModal = () => {
            vfm.close(modalId);
        };

        return {
            handleDownloadDocument,
            openModal,
            closenModal,
            modalId,
            url,
        };
    },
};
</script>
<style lang="scss" scoped>
.file {
    cursor: pointer;
    border: 1px solid #efefef;
    border-radius: 5px;
    background-color: #fdfdfd;
    height: 100%;
}
.file:hover {
    background-color: #efefef;
    border-color: #fdfdfd;
}
</style>
