<template>
    <div class="col-12 text-end">
        <div
            role="button"
            v-for="t in list"
            class="btn btn-primary px-2 ms-3 mb-1"
            :key="t.template._id"
            @click="$emit('onClick', t.template._id)"
        >
            <i class="far fa-file"></i>
            {{ t.template.title }}
        </div>
    </div>
</template>
<script>
import { computed } from 'vue';

export default {
    name: 'RenderButtonsBar',
    props: {
        setup: Object,
    },
    setup(props) {
        const list = computed(() => props.setup.templates.filter((t) => t.display));

        return { list };
    },
};
</script>
<style lang="scss"></style>
