import { ref } from 'vue';
import axios from '@/services/useAxios';

export default function useConsultaSetup() {
    const consultaSetup = ref();
    const consultaSetups = ref([]);

    const getConsultaSetup = async (id) => {
        const res = await axios.get(`/care/consultas/setup/${id}`);

        if (res.status === 200) {
            consultaSetup.value = res.data;
        }
    };

    const getConsultaSetups = async (searchQuery = '') => {
        const query = searchQuery ? `?searchQuery=${searchQuery}` : '';

        const res = await axios.get(`/care/consultas/setup${query}`);

        if (res.status === 200) {
            consultaSetups.value = res.data.items;
        }
    };

    const createConsultaSetup = async (data) => {
        const res = await axios.post('/care/consultas/setup', data);
        if (res.status === 201) {
            // console.log(res.data);
            consultaSetup.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const updateConsultaSetup = async (id, data) => {
        const res = await axios.put(`/care/consultas/setup/${id}`, data);
        if (res.status === 200) {
            // console.log(res.data);
            consultaSetup.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const getPairing = async (_especialidades) => {
        try {
            // eslint-disable-next-line no-unused-vars
            let especialidades;
            if (!Array.isArray(_especialidades)) {
                especialidades = [_especialidades];
            } else {
                especialidades = _especialidades;
            }
            const response = await axios.post('/care/consultas/setup/pairing', { especialidades });

            if (response.status !== 200) {
                // Handle Error
            }

            consultaSetup.value = response.data;
        } catch (error) {
            if (error.status === 404) {
                // Handle Error
            }
            console.log(error.statusText);
        }
    };

    return {
        consultaSetup,
        consultaSetups,
        getPairing,
        getConsultaSetup,
        getConsultaSetups,
        createConsultaSetup,
        updateConsultaSetup,
    };
}
