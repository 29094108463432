<template>
    <div class="row">
        <div class="col-lg-6 order-2 order-lg-2">
            <div class="h-100 w-100 border">
                <div class="d-flex mb-1" v-for="(d, i) of modelValue" :key="i">
                    <div>
                        <p class="mb-0 lh-sm">{{ i + 1 }}. {{ d.campoUno }}</p>
                        <p v-if="d.campoDos" class="mb-0 lh-sm">{{ d.campoDos }}</p>
                    </div>
                    <div class="d-flex ms-1">
                        <i
                            v-if="!disabled"
                            role="button"
                            @click="handleMoveMedicamento(i, '-')"
                            class="fas fa-chevron-up p-1"
                        ></i>
                        <i
                            v-if="!disabled"
                            role="button"
                            @click="handleMoveMedicamento(i, '+')"
                            class="fas fa-chevron-down p-1"
                        ></i
                        ><i
                            v-if="!disabled"
                            role="button"
                            @click="handleDeleteMedicamento(i)"
                            class="fa fa-trash text-danger p-1"
                        ></i>
                    </div>
                </div>
                <!-- <p v-for="(d, i) of modelValue" :key="i" class="mb-0">
                    {{ i + 1 }}. {{ d.campoUno }} \n
                    <i
                        role="button"
                        @click="handleMoveDiagnostico(i, '-')"
                        class="fas fa-chevron-up p-1"
                    ></i>
                    <i
                        role="button"
                        @click="handleMoveDiagnostico(i, '+')"
                        class="fas fa-chevron-down p-1"
                    ></i
                    ><i
                        role="button"
                        @click="handleDeleteDiagnostico(i)"
                        class="fa fa-trash text-danger p-1"
                    ></i>
                </p> -->
            </div>
        </div>
        <div class="col-lg-6 order-1 order-lg-2" v-if="!disabled">
            <div class="row mb-1" v-if="showSearcher">
                <label class="col-md-3 col-form-label fw-light">Medicamento</label>
                <div class="col-md-9">
                    <Autocomplete
                        :debounce="1200"
                        @onInput="getMedicamentos"
                        :items="medicamentos"
                        :displayItem="(item) => (typeof item === 'string' ? item : item.selectName)"
                        placeholder="Buscar un medicamento"
                        @onSelect="onSelectMedicamento"
                        ref="medicamentoAutocomplete"
                        :key="autocompleteKey"
                    ></Autocomplete>
                </div>
            </div>
            <div class="row mb-1">
                <label class="col-md-3 col-form-label fw-light">Seleccionado</label>
                <div class="col-md-9">
                    <input type="text" class="form-control" v-model="medicamentoSelected" />
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-md-3 col-form-label fw-light">Indicaciones</label>
                <div class="col-md-9">
                    <textarea class="form-control" v-model="indicaciones"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-end">
                    <button class="btn btn-sm btn-outline-primary" @click="handleAddMedicamento">
                        Agregar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue';
import Autocomplete from '@/components/form/Autocomplete.vue';
import useMedicamentos from '@/modules/Care/Medicamentos/services/useMedicamentos';

export default {
    name: 'DiagnosticosRipsForm',
    components: { Autocomplete },
    props: {
        modelValue: Array,
        disabled: Boolean,
        showSearcher: { type: Boolean, default: true },
    },
    setup(props, { emit }) {
        const medicamentoSelected = ref('');
        const indicaciones = ref('');
        const { medicamentos, getMedicamentos } = useMedicamentos();

        const autocompleteKey = ref(Math.floor(Math.random() * 1000));
        const medicamentoAutocomplete = ref(); // autocomplete input
        const onSelectMedicamento = (selected) => {
            // if (Array.isArray(props.modelValue)) {
            //     emit('update:modelValue', [...props.modelValue, selected.selectName]);
            // } else {
            //     emit('update:modelValue', [selected.selectName]);
            // }
            medicamentoSelected.value = selected.selectName;
            indicaciones.value = selected.indicaciones;
            medicamentos.value = [];
            autocompleteKey.value = Math.floor(Math.random() * 1000);
            medicamentoAutocomplete.value.setText(selected.selectName);
        };

        const handleMoveMedicamento = (index, op) => {
            const temp = [...props.modelValue];
            const value = props.modelValue[index];
            temp.splice(index, 1);
            if (op === '+') {
                temp.splice(index + 1, 0, value);
            } else {
                temp.splice(index - 1, 0, value);
            }
            emit('update:modelValue', temp);
        };

        const handleDeleteMedicamento = (index) => {
            const temp = [...props.modelValue];
            temp.splice(index, 1);
            emit('update:modelValue', temp);
        };

        const handleAddMedicamento = () => {
            const recomendacion = {
                campoUno: medicamentoSelected.value,
                campoDos: indicaciones.value,
            };
            if (Array.isArray(props.modelValue)) {
                emit('update:modelValue', [...props.modelValue, recomendacion]);
            } else {
                emit('update:modelValue', [recomendacion]);
            }
            medicamentoSelected.value = '';
            indicaciones.value = '';
        };

        return {
            medicamentos,
            getMedicamentos,
            onSelectMedicamento,
            medicamentoAutocomplete,
            handleMoveMedicamento,
            handleDeleteMedicamento,
            autocompleteKey,
            medicamentoSelected,
            indicaciones,
            handleAddMedicamento,
        };
    },
};
</script>
<style scoped></style>
