<template>
    <div class="card-body theme-form" v-if="consultaSetup">
        <div class="row" :class="{'mb-3': !isReadOnly}">
            <div class="col">
                <p class="mb-0">Especialidad:</p>
                <p class="mb-0 fs-4 fw-bolder">
                    {{ consultaSetup.name }}
                    <i
                        role="button"
                        class="ms-2 edit-link fa fa-edit"
                        v-if="!isReadOnly"
                        @click="handleEdit"
                    ></i>
                </p>
                <!-- <div class="d-flex flex-wrap">
                    <p class="pe-3 mb-0">
                        <i class="fa fa-credit-card"></i> {{ doctor.specialty.name }}
                    </p>
                </div> -->
            </div>
        </div>
    </div>
    <div class="card-body theme-form" v-else>
        <div class="row">
            <div class="col">
                <div class="row" :class="{ 'is-invalid': v.consultaSetup.$errors.length }">
                    <label class="col-md-3 col-form-label">Especialidad</label>
                    <div class="col-md-9" :class="{ 'is-invalid': v.consultaSetup.$errors.length }">
                        <Autocomplete
                            :class="{ 'is-invalid': v.consultaSetup.$errors.length }"
                            :debounce="1200"
                            @onInput="getConsultaSetups"
                            :items="consultaSetups"
                            :displayItem="
                                (item) => (typeof item === 'string' ? item : item.name)
                            "
                            placeholder="Escoger tipo de consulta"
                            @onSelect="onSelectDoctor"
                            ref="consultaSetupAutocomplete"
                        ></Autocomplete>
                    </div>
                    <div class="invalid-feedback" v-if="v.consultaSetup.$errors.length">
                        {{ v.consultaSetup.$errors[0].$message }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { computed, ref, onBeforeMount, watch } from 'vue';
import Autocomplete from '@/components/form/Autocomplete.vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import useConsultaSetup from '@/modules/Care/ConsultaSetup/services/useConsultaSetup';

export default {
    name: 'ConsultaSetupInfo',
    components: { Autocomplete },
    props: {
        modelValue: String,
        isReadOnly: Boolean,
    },
    setup(props, { emit }) {
        // eslint-disable-next-line object-curly-newline, operator-linebreak
        const { consultaSetups, getConsultaSetups, getConsultaSetup, consultaSetup } =
            useConsultaSetup();

        /** ***** Autocompleters ***** */
        const consultaSetupAutocomplete = ref(); // autocomplete input
        const onSelectDoctor = (selected) => {
            emit('update:modelValue', selected.id);
            consultaSetupAutocomplete.value.setText(selected.selectName);
            consultaSetup.value = selected;
            consultaSetups.value = [];
            emit('onChange', selected);
        };

        /** ***** Validator ***** */
        const rules = computed(() => ({
            consultaSetup: { required },
        }));

        const state = computed(() => ({ consultaSetup: props.modelValue }));

        const v = useVuelidate(rules, state);

        onBeforeMount(() => {
            if (props.modelValue) {
                getConsultaSetup(props.modelValue);
            }
        });

        watch(
            () => props.modelValue,
            (newVal) => {
                if (newVal) {
                    if (consultaSetup.value) {
                        if (newVal && newVal !== consultaSetup.value.id) {
                            getConsultaSetup(props.modelValue);
                        }
                    } else if (newVal) {
                        getConsultaSetup(props.modelValue);
                    }
                } else {
                    consultaSetup.value = null;
                }
                // eslint-disable-next-line comma-dangle
            }
        );

        const handleEdit = () => {
            emit('update:modelValue', '');
            emit('onChange', null);
            consultaSetup.value = null;
        };

        return {
            consultaSetups,
            getConsultaSetups,
            onSelectDoctor,
            consultaSetupAutocomplete,
            consultaSetup,
            handleEdit,
            v,
        };
    },
};
</script>
<style scoped>
.edit-link {
    font-size: 0.5em;
}

.collapsed i {
    transform: rotate(180deg);
}
</style>
