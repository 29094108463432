<template>
    <div>
        <div class="d-flex align-items-center justify-content-between collapsed border-bottom mb-2"
            data-bs-toggle="collapse" :data-bs-target="`#collapse-${collpaseId}`" :aria-expanded="true"
            :aria-controls="`collapse-${collpaseId}`" role="button">
            <label role="button" class="col-form-label">Formula de Gafas</label>
            <i class="fas fa-angle-up"></i>
        </div>
        <div class="collapse" :id="`collapse-${collpaseId}`">
            <div class="row mb-3">
                <div class="table-responsive">
                    <table class="table align-middle">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Esferico</th>
                                <th>Cilindrico</th>
                                <th>
                                    Eje&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </th>
                                <th>Prisma</th>
                                <th>Base&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowspan="2" class="">Lejos</td>
                                <td>Ojo Derecho</td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.lejos_derecho_esferico"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.lejos_derecho_cilindrico"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.lejos_derecho_eje"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.lejos_derecho_prisma"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.lejos_derecho_base"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td>Ojo Izquierdo</td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.lejos_izquierdo_esferico"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.lejos_izquierdo_cilindrico"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.lejos_izquierdo_eje"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.lejos_izquierdo_prisma"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.lejos_izquierdo_base"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="2" class="">Adicion</td>
                                <td>Ojo Derecho</td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.adicion_derecho_esferico"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.adicion_derecho_cilindrico"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.adicion_derecho_eje"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.adicion_derecho_prisma"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.adicion_derecho_base"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td>Ojo Izquierdo</td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.adicion_izquierdo_esferico"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.adicion_izquierdo_cilindrico"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.adicion_izquierdo_eje"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.adicion_izquierdo_prisma"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.adicion_izquierdo_base"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="2" class="">Cerca</td>
                                <td>Ojo Derecho</td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.cerca_derecho_esferico"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.cerca_derecho_cilindrico"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.cerca_derecho_eje"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.cerca_derecho_prisma"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.cerca_derecho_base"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td>Ojo Izquierdo</td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.cerca_izquierdo_esferico"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.cerca_izquierdo_cilindrico"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.cerca_izquierdo_eje"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.cerca_izquierdo_prisma"></textarea>
                                </td>
                                <td>
                                    <textarea class="form-control h-100 w-100" :disabled="disabled"
                                        v-model="state.cerca_izquierdo_base"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-md-3 col-form-label">Distancia Pupilar</label>
                <div class="col-sm-9">
                    <input class="form-control" type="text" :disabled="disabled" v-model="state.distnacia_pupilar" />
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-md-3 col-form-label">TIpo de lente</label>
                <div class="col-sm-9">
                    <input class="form-control" type="text" :disabled="disabled" v-model="state.tipo_lente" />
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-md-3 col-form-label">Material</label>
                <div class="col-sm-9">
                    <input class="form-control" type="text" :disabled="disabled" v-model="state.material" />
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-md-3 col-form-label">Tratamientos</label>
                <div class="col-sm-9">
                    <textarea class="form-control" :disabled="disabled" v-model="state.tratamientos"></textarea>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-md-3 col-form-label">Observaciones</label>
                <div class="col-sm-9">
                    <textarea class="form-control" :disabled="disabled" v-model="state.observaciones"></textarea>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { onBeforeMount, ref, reactive, watch } from 'vue';

export default {
    name: 'FormulaGafasForm',
    props: {
        modelValue: Object,
        disabled: Boolean,
    },
    setup(props, { emit }) {
        const state = reactive({
            lejos_derecho_esferico: '',
            lejos_derecho_cilindrico: '',
            lejos_derecho_eje: '',
            lejos_derecho_prisma: '',
            lejos_derecho_base: '',
            lejos_izquierdo_esferico: '',
            lejos_izquierdo_cilindrico: '',
            lejos_izquierdo_eje: '',
            lejos_izquierdo_prisma: '',
            lejos_izquierdo_base: '',
            adicion_derecho_esferico: '',
            adicion_derecho_cilindrico: '',
            adicion_derecho_eje: '',
            adicion_derecho_prisma: '',
            adicion_derecho_base: '',
            adicion_izquierdo_esferico: '',
            adicion_izquierdo_cilindrico: '',
            adicion_izquierdo_eje: '',
            adicion_izquierdo_prisma: '',
            adicion_izquierdo_base: '',
            cerca_derecho_esferico: '',
            cerca_derecho_cilindrico: '',
            cerca_derecho_eje: '',
            cerca_derecho_prisma: '',
            cerca_derecho_base: '',
            cerca_izquierdo_esferico: '',
            cerca_izquierdo_cilindrico: '',
            cerca_izquierdo_eje: '',
            cerca_izquierdo_prisma: '',
            cerca_izquierdo_base: '',
            distnacia_pupilar: '',
            tipo_lente: '',
            material: '',
            tratamientos: '',
            observaciones: '',
        });

        const resetState = () => {
            state.lejos_derecho_esferico = '';
            state.lejos_derecho_cilindrico = '';
            state.lejos_derecho_eje = '';
            state.lejos_derecho_prisma = '';
            state.lejos_derecho_base = '';
            state.lejos_izquierdo_esferico = '';
            state.lejos_izquierdo_cilindrico = '';
            state.lejos_izquierdo_eje = '';
            state.lejos_izquierdo_prisma = '';
            state.lejos_izquierdo_base = '';
            state.adicion_derecho_esferico = '';
            state.adicion_derecho_cilindrico = '';
            state.adicion_derecho_eje = '';
            state.adicion_derecho_prisma = '';
            state.adicion_derecho_base = '';
            state.adicion_izquierdo_esferico = '';
            state.adicion_izquierdo_cilindrico = '';
            state.adicion_izquierdo_eje = '';
            state.adicion_izquierdo_prisma = '';
            state.adicion_izquierdo_base = '';
            state.cerca_derecho_esferico = '';
            state.cerca_derecho_cilindrico = '';
            state.cerca_derecho_eje = '';
            state.cerca_derecho_prisma = '';
            state.cerca_derecho_base = '';
            state.cerca_izquierdo_esferico = '';
            state.cerca_izquierdo_cilindrico = '';
            state.cerca_izquierdo_eje = '';
            state.cerca_izquierdo_prisma = '';
            state.cerca_izquierdo_base = '';
            state.distnacia_pupilar = '';
            state.tipo_lente = '';
            state.material = '';
            state.tratamientos = '';
            state.observaciones = '';
        };

        const collpaseId = ref(Math.floor(Math.random() * 1000));

        const setState = (data) => {
            if (data) {
                state.lejos_derecho_esferico = data.lejos_derecho_esferico;
                state.lejos_derecho_cilindrico = data.lejos_derecho_cilindrico;
                state.lejos_derecho_eje = data.lejos_derecho_eje;
                state.lejos_derecho_prisma = data.lejos_derecho_prisma;
                state.lejos_derecho_base = data.lejos_derecho_base;
                state.lejos_izquierdo_esferico = data.lejos_izquierdo_esferico;
                state.lejos_izquierdo_cilindrico = data.lejos_izquierdo_cilindrico;
                state.lejos_izquierdo_eje = data.lejos_izquierdo_eje;
                state.lejos_izquierdo_prisma = data.lejos_izquierdo_prisma;
                state.lejos_izquierdo_base = data.lejos_izquierdo_base;
                state.adicion_derecho_esferico = data.adicion_derecho_esferico;
                state.adicion_derecho_cilindrico = data.adicion_derecho_cilindrico;
                state.adicion_derecho_eje = data.adicion_derecho_eje;
                state.adicion_derecho_prisma = data.adicion_derecho_prisma;
                state.adicion_derecho_base = data.adicion_derecho_base;
                state.adicion_izquierdo_esferico = data.adicion_izquierdo_esferico;
                state.adicion_izquierdo_cilindrico = data.adicion_izquierdo_cilindrico;
                state.adicion_izquierdo_eje = data.adicion_izquierdo_eje;
                state.adicion_izquierdo_prisma = data.adicion_izquierdo_prisma;
                state.adicion_izquierdo_base = data.adicion_izquierdo_base;
                state.cerca_derecho_esferico = data.cerca_derecho_esferico;
                state.cerca_derecho_cilindrico = data.cerca_derecho_cilindrico;
                state.cerca_derecho_eje = data.cerca_derecho_eje;
                state.cerca_derecho_prisma = data.cerca_derecho_prisma;
                state.cerca_derecho_base = data.cerca_derecho_base;
                state.cerca_izquierdo_esferico = data.cerca_izquierdo_esferico;
                state.cerca_izquierdo_cilindrico = data.cerca_izquierdo_cilindrico;
                state.cerca_izquierdo_eje = data.cerca_izquierdo_eje;
                state.cerca_izquierdo_prisma = data.cerca_izquierdo_prisma;
                state.cerca_izquierdo_base = data.cerca_izquierdo_base;
                state.distnacia_pupilar = data.distnacia_pupilar;
                state.tipo_lente = data.tipo_lente;
                state.material = data.material;
                state.tratamientos = data.tratamientos;
                state.observaciones = data.observaciones;
            } else {
                resetState();
            }
        };

        onBeforeMount(() => {
            if (props.modelValue) {
                setState(props.modelValue);
            }
        });
        watch(state, (newVal) => {
            emit('update:modelValue', newVal);
            // eslint-disable-next-line comma-dangle
        });

        watch(
            () => props.modelValue,
            (newVal) => {
                console.log('watcho de la formula de gafas');
                setState(newVal);
                // eslint-disable-next-line comma-dangle
            }
        );
        return { collpaseId, state };
    },
};
</script>
<style scoped></style>
