<template>
    <div class="row">
        <div class="col-lg-6 order-2 order-lg-2">
            <div class="h-100 w-100 border">
                <p v-for="(d, i) of modelValue" :key="i" class="mb-0">
                    {{ i + 1 }}. {{ d }}
                    <i
                        v-if="!disabled"
                        role="button"
                        @click="handleMoveDiagnostico(i, '-')"
                        class="fas fa-chevron-up p-1"
                    ></i>
                    <i
                        v-if="!disabled"
                        role="button"
                        @click="handleMoveDiagnostico(i, '+')"
                        class="fas fa-chevron-down p-1"
                    ></i
                    ><i
                        v-if="!disabled"
                        role="button"
                        @click="handleDeleteDiagnostico(i)"
                        class="fa fa-trash text-danger p-1"
                    ></i>
                </p>
            </div>
        </div>
        <div class="col-lg-6 order-1 order-lg-2" v-if="!disabled">
            <div class="row">
                <label class="col-md-3 col-form-label fw-light">Buscar diagnostico</label>
                <div class="col-md-9">
                    <Autocomplete
                        :debounce="1200"
                        @onInput="getDiagnosticos"
                        :items="diagnosticos"
                        :displayItem="(item) => (typeof item === 'string' ? item : item.selectName)"
                        placeholder="Buscar un diagnostico"
                        @onSelect="onSelectDiagnostico"
                        ref="diagnosticoAutocomplete"
                        :key="autocompleteKey"
                    ></Autocomplete>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue';
import Autocomplete from '@/components/form/Autocomplete.vue';
import useDiagnosticos from '@/modules/Care/Diagnostico/services/useDiagnostico';

export default {
    name: 'DiagnosticosRipsForm',
    components: { Autocomplete },
    props: {
        modelValue: Array,
        disabled: Boolean,
    },
    setup(props, { emit }) {
        const { diagnosticos, getDiagnosticos } = useDiagnosticos();

        const autocompleteKey = ref(Math.floor(Math.random() * 1000));
        const diagnosticoAutocomplete = ref(); // autocomplete input
        const onSelectDiagnostico = (selected) => {
            if (Array.isArray(props.modelValue)) {
                emit('update:modelValue', [...props.modelValue, selected.selectName]);
            } else {
                emit('update:modelValue', [selected.selectName]);
            }
            diagnosticoAutocomplete.value.setText(selected.selectName);
            diagnosticos.value = [];
            autocompleteKey.value = Math.floor(Math.random() * 1000);
        };

        const handleMoveDiagnostico = (index, op) => {
            const temp = [...props.modelValue];
            const value = props.modelValue[index];
            temp.splice(index, 1);
            if (op === '+') {
                temp.splice(index + 1, 0, value);
            } else {
                temp.splice(index - 1, 0, value);
            }
            emit('update:modelValue', temp);
        };

        const handleDeleteDiagnostico = (index) => {
            const temp = [...props.modelValue];
            temp.splice(index, 1);
            emit('update:modelValue', temp);
        };

        return {
            diagnosticos,
            getDiagnosticos,
            onSelectDiagnostico,
            diagnosticoAutocomplete,
            handleMoveDiagnostico,
            handleDeleteDiagnostico,
            autocompleteKey,
        };
    },
};
</script>
<style scoped></style>
