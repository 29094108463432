<template>
    <div>
        <div class="form-check form-check-inline">
            <input
                class="form-check-input"
                type="radio"
                :name="'radio_' + id"
                :id="'radio_1' + id"
                :value="'Si'"
                v-model="value"
            />
            <label class="form-check-label" :for="'radio_1' + id"> Si </label>
        </div>
        <div class="form-check form-check-inline">
            <input
                class="form-check-input"
                type="radio"
                :name="'radio_' + id"
                :id="'radio_2' + id"
                :value="'No'"
                v-model="value"
                :checked="value !== 'Si'"
            />
            <label class="form-check-label" :for="'radio_2' + id"> No </label>
        </div>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline, no-unused-vars
import { ref, computed, nextTick, onMounted, watch } from 'vue';

export default {
    name: 'SiNoCheckInput',
    props: {
        modelValue: String,
    },
    setup(props, { emit }) {
        const id = ref(Math.random());

        const value = computed({
            get() {
                console.log('props.modelValue', props.modelValue);
                return props.modelValue;
            },
            set(v) {
                emit('update:modelValue', v);
            },
        });

        return { id, value };
    },
};
</script>
<style lang="scss" scoped></style>
