<template>
    <div class="card-body theme-form">
        <div class="row" v-if="doctoresSelected" :class="{ 'mb-3': !isReadOnly && !isCompleted }">
            <div class="col-lg-6 mb-2" v-for="doctor in doctoresSelected" :key="doctor._id">
                <p class="mb-0">Especialista:</p>
                <p class="mb-0 fs-4 fw-bolder">
                    {{ doctor.fullName
                    }}<i
                        role="button"
                        class="ms-2 edit-link fa fa-trash"
                        v-if="!isReadOnly"
                        @click="handleRemove(doctor.id)"
                    ></i>
                </p>
                <div class="d-flex flex-wrap">
                    <p class="pe-3 mb-0" v-if="doctor.specialty?.name">
                        <i class="fa fa-credit-card"></i> {{ doctor.specialty?.name }}
                    </p>
                </div>
            </div>
        </div>
        <div class="row" v-if="!isReadOnly && !isCompleted">
            <div class="col">
                <div class="row" :class="{ 'is-invalid': v.doctor.$errors.length }">
                    <label class="col-md-3 col-form-label">Especialista</label>
                    <div class="col-md-9" :class="{ 'is-invalid': v.doctor.$errors.length }">
                        <Autocomplete
                            :class="{ 'is-invalid': v.doctor.$errors.length }"
                            :debounce="1200"
                            @onInput="getDoctores"
                            :items="doctores"
                            :displayItem="
                                (item) => (typeof item === 'string' ? item : item.fullName)
                            "
                            placeholder="Buscar un especialista"
                            @onSelect="onSelectDoctor"
                            ref="doctorAutocomplete"
                            :key="doctorAutocompleteKey"
                        ></Autocomplete>
                    </div>
                    <div class="invalid-feedback" v-if="v.doctor.$errors.length">
                        {{ v.doctor.$errors[0].$message }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { computed, ref, onBeforeMount, watch } from 'vue';
import Autocomplete from '@/components/form/Autocomplete.vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import useDoctor from '@/modules/Care/Doctores/services/useDoctor';

export default {
    name: 'DoctoresInfo',
    components: { Autocomplete },
    props: {
        modelValue: Array || String,
        isReadOnly: Boolean,
        maxDoctores: { type: Number, default: 1 },
    },
    setup(props, { emit }) {
        const doctoresSelected = ref([]);
        const isCompleted = computed(() => doctoresSelected.value.length === props.maxDoctores);

        // eslint-disable-next-line object-curly-newline, operator-linebreak
        const { doctores, getDoctores, getDoctor, getNacimientoFormated, getEdad } = useDoctor();

        const emitOnChange = () =>
            // eslint-disable-next-line implicit-arrow-linebreak
            emit(
                'onChange',
                // eslint-disable-next-line comma-dangle, no-underscore-dangle
                doctoresSelected.value.map((d) => d.specialty._id)
            );

        const getDoctorPromise = (id) =>
            // eslint-disable-next-line implicit-arrow-linebreak
            new Promise((resolve) => {
                resolve(getDoctor(id));
            });

        const loadDoctores = async () => {
            if (props.modelValue) {
                // doctoresSelected.value = [];
                const promesas = [];
                if (Array.isArray(props.modelValue)) {
                    console.log('DoctoresInfo 1', props.modelValue);
                    // eslint-disable-next-line no-restricted-syntax
                    for (const e of props.modelValue) {
                        console.log('DoctoresInfo 2', e);
                        promesas.push(getDoctorPromise(e));
                    }
                } else {
                    promesas.push(getDoctorPromise(props.modelValue));
                }
                doctoresSelected.value = await Promise.all(promesas);
                console.log('loadDoctores', doctoresSelected.value);
            }
        };

        /** ***** Autocompleters ***** */
        const doctorAutocomplete = ref(); // autocomplete input
        const doctorAutocompleteKey = ref(Date.now());
        const onSelectDoctor = (selected) => {
            if (!doctoresSelected.value.find((d) => d.id === selected.id)) {
                console.log('DoctoresInfo 3', selected);
                emit('update:modelValue', [...props.modelValue, selected.id]);
                doctoresSelected.value.push(selected);
                emitOnChange();
            }
            doctorAutocomplete.value.setText('');
            doctores.value = [];
            doctorAutocompleteKey.value = Date.now();
        };

        /** ***** Validator ***** */
        const rules = computed(() => ({
            doctor: { required },
        }));

        const state = computed(() => ({ doctor: props.modelValue }));

        const v = useVuelidate(rules, state);

        onBeforeMount(loadDoctores);

        watch(
            () => props.modelValue,
            () => {
                loadDoctores();
                // eslint-disable-next-line comma-dangle
            }
        );

        const handleRemove = (id) => {
            emit(
                'update:modelValue',
                // eslint-disable-next-line comma-dangle
                props.modelValue.filter((d) => {
                    console.log(`${d} !== ${id}`);
                    return d !== id;
                    // eslint-disable-next-line comma-dangle
                })
            );
            emitOnChange();
        };

        return {
            doctoresSelected,
            doctores,
            getDoctores,
            onSelectDoctor,
            doctorAutocomplete,
            doctorAutocompleteKey,
            getNacimientoFormated,
            getEdad,
            handleRemove,
            v,
            isCompleted,
        };
    },
};
</script>
<style scoped>
.edit-link {
    font-size: 0.5em;
}

.collapsed i {
    transform: rotate(180deg);
}
</style>
